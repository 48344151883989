import image_1 from "./10.jpg";
import image_2 from "./11.jpg";
import image_3 from "./12.jpg";
import image_4 from "./23.jpg";
import image_5 from "./24.jpg";
import image_6 from "./21.jpg";
import image_7 from "./9.jpg";
import image_8 from "./20.jpg";
import image_9 from "./19.jpg";

export default [
  { img: image_1, alt: "Instalare structură de lemn" },
  { img: image_2, alt: "Montare plasă metalică pe structură de lemn" },
  { img: image_3, alt: "Mascare structură de lemn" },
  { img: image_4, alt: "Montare plasă metalică" },
  { img: image_5, alt: "Fixare structură de lemn" },
  { img: image_6, alt: "Lucrare de podire, creare spațiu suplimentar" },
  { img: image_7, alt: "Montare strutură metalică" },
  { img: image_8, alt: "Aplicare lavabilă" },
  { img: image_9, alt: "Mascare structură metalică" },
];
