import {
  faClock,
  faHandshake,
  faHandshakeSimple,
  faHelmetSafety,
  faLightbulb,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function AboutUs() {
  return (
    <div className="bg-[#0C0C0C] w-full h-auto flex flex-wrap mt-13 mb-16">
      <div className="flex-1 m-auto w-[20%] min-w-[300px] ml-[5%] mr-[5%] text-white mb-20">
        <div className="w-[150px] aspect-square border-[rgb(217,179,70)] border-2 rounded-full ml-auto mr-auto mb-2 mt-10">
          <div className="w-full h-full justify-center flex text-[32px]">
            <FontAwesomeIcon
              className="m-auto"
              icon={faLightbulb}
              size={"2xl"}
              color="rgb(217,179,70)"
            />
          </div>
        </div>

        <p className="text-center text-[rgb(217,179,70)] font-sans font-bold text-[32px] mb-10">
          Ideea ta
        </p>

        <p className=" text-center ml-5 mr-5 mb-2">
          Ai o idee despre cum să arate casa visurilor tale?
        </p>
        <p className="text-center ml-5 mr-5 mb-1">
          Drumul de la o idee la rezultatul dorit este întotdeauna o experiență frumoasă,
          însă, implică multă responsabilitate!
        </p>
        <p className="text-center ml-5 mr-5 mb-2">
          Noi ne ocupăm de aspectele de finețe ce vor da viață ideii tale !
        </p>
      </div>
      <div className="flex-1 m-auto w-[20%] min-w-[300px] ml-[5%] mr-[5%] text-white mb-20">
        <div className="w-[150px] aspect-square border-[rgb(217,179,70)] border-2 rounded-full ml-auto mr-auto mb-2 mt-10">
          <div className="w-full h-full justify-center flex text-[32px]">
            <FontAwesomeIcon
              className="m-auto"
              icon={faHandshake}
              size={"2xl"}
              color="rgb(217,179,70)"
            />
          </div>
        </div>

        <p className="text-center text-[rgb(217,179,70)] font-bold text-[32px] mb-10">
          Echipa perfectă
        </p>

        <p className="text-center ml-5 mr-5 mb-1">Om cu om am construit echipa !</p>
        <p className="text-center ml-5 mr-5 mb-1">
          Avem oameni specializați ce vor ocupa orice poziție în procesul de construcție
          sau amenajare, echipament modern și colaboratori implicați. Construim una din
          activele cele mai importante ale unei persoane, construim acasă.
        </p>
      </div>
      <div className="flex-1 m-auto w-[20%] min-w-[300px] ml-[5%] mr-[5%] text-white mb-20">
        <div className="w-[150px] aspect-square border-[rgb(217,179,70)] border-2 rounded-full ml-auto mr-auto mb-2 mt-10">
          <div className="w-full h-full justify-center flex text-[32px]">
            <FontAwesomeIcon
              className="m-auto"
              icon={faClock}
              size={"2xl"}
              color="rgb(217,179,70)"
            />
          </div>
        </div>

        <p className="text-center text-[rgb(217,179,70)] font-bold text-[32px] mb-10">
          Eficiență
        </p>

        <p className="text-center ml-5 mr-5 mb-1">
          Suntem oameni dedicați și capabili, reușind să gasim soluții pentru a mulțumi
          orice pretenție respectând regulile de construcție.
        </p>

        <p className="text-center ml-5 mr-5">
          În urma unei analize de etapizare, oferim termene de execuție reale.
        </p>
        <p className="text-center ml-5 mr-5 mb-1">
          Lucrăm rapid, la standarde înalte de calitate și în siguranța. Într-un cuvânt,
          eficient!
        </p>
      </div>
    </div>
  );
}
