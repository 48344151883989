import React, { useEffect, useState } from "react";
import AnimatedLogo from "./AnimatedLogo.jsx";

export default function LandingPage() {
  const [className, setClassName] = useState(false);

  return (
    <div className="h-[100vh] w-[100vw] flex-column flex-wrap bg-[#0C0C0C]">
      <div className="flex-1 min-w-[400px] w-[50vw] m-auto">
        <div className="m-auto">
          <AnimatedLogo className="mt-[25vh] md:mt-[15vh] lg:mt-[15vh] xl:mt-[15vh] 2xl:mt-[15vh] ml-auto mr-auto" />
        </div>
      </div>
      <div className="justify-center flex">
        <h1 className="text-white text-[28px] text-center mt-28 md:mt-7 lg:mt-7 xl:mt-7 2xl:mt-7 animate-[textAppear_2s_ease-in-out_1]">
          Construim acasă
        </h1>
      </div>
      <div className="justify-center flex mt-[70px]">
        <div className="scroll-down">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}
