import image_1 from "./12.png";
import image_2 from "./10.jpg";
import image_3 from "./14.png";

import image_4 from "./0.jpg";

import image_5 from "./7.jpg";

import image_6 from "./9.jpg";

import image_7 from "./6.jpg";

import image_8 from "./15.jpg";

import image_9 from "./4.jpg";

export default [
  { img: image_1, alt: "Comparație lucrare montare termosistem" },
  { img: image_2, alt: "Montare termosistem" },
  { img: image_3, alt: "Aplicare tencuială decorativă" },
  { img: image_4, alt: "Montare schelă metalică" },
  { img: image_5, alt: "Aplicare bordură de piatră" },
  { img: image_6, alt: "Aplicare tencuială" },
  { img: image_7, alt: "Consolidare poartă intrare cu arcadă" },
  { img: image_8, alt: "Consolidare gard din ciment" },
  { img: image_9, alt: "Montare termosistem în apropierea ferestrelor" },
];
