import React, { useState } from "react";
import {
  faMailBulk,
  faMailReply,
  faMessage,
  faPhone,
  faSquareEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import emailjs from "@emailjs/browser";

export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  return (
    <div className="flex justify-center bg-gray-100">
      <div className="contact-form-wrapper d-flex justify-content-center">
        <form
          action="#"
          className="contact-form"
          onSubmit={(e) => {
            e.preventDefault();
            emailjs
              .send(
                "service_h3psvai",
                "template_71s4uoj",
                { name, email, message },
                "glW-2gxsy1cTuTlsO"
              )
              .then(
                function (response) {
                  Swal.fire({
                    icon: "success",
                    title: "Am primit mesajul tău!",
                    text: "Vom reveni cu un raspuns in cel mai scurt timp",
                  });
                  setName("");
                  setEmail("");
                  setMessage("");
                },
                function (error) {
                  Swal.fire({
                    icon: "error",
                    title: "Mesajul nu a putut fi trimis!",
                    text: "Te rugăm sa ne contactezi folosind datele de contact furnizate mai jos!",
                  });

                  setName("");
                  setEmail("");
                  setMessage("");
                }
              );
          }}
        >
          <h5 className="title">Ai intrebări?</h5>
          <p className="description">Îți vom raspunde în cel mai scurt timp!</p>
          <div className="mt-5">
            <input
              type="text"
              className="form-control rounded border-white mb-3 form-input"
              id="name"
              placeholder="Nume"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              required
            />
          </div>
          <div>
            <input
              type="email"
              className="form-control rounded border-white mb-3 form-input"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              required
            />
          </div>
          <div>
            <textarea
              id="message"
              className="form-control rounded border-white mb-3 form-text-area pt-4"
              rows="5"
              cols="30"
              placeholder="Întrebare"
              value={message}
              onChange={(e) => setMessage(e.currentTarget.value)}
              required
            ></textarea>
          </div>
          <div className="submit-button-wrapper">
            <input type="submit" value="Trimite" />
          </div>
          <p className="description mt-7">Sau ne poți contacta folosind</p>

          <div className="flex-column mt-5 mb-2 ml-[50px]">
            <div className="flex">
              <div className="mt-[-3px]">
                <a href="#">
                  <i
                    className="fa fa-envelope text-[rgb(217,179,70)]"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
              <p className="card--content leading-tight ml-2">
                contact@sofimarexecutive.ro
              </p>
            </div>
          </div>
          <div className="flex-column ml-[50px] mt-3">
            <div className="flex">
              <div className="mt-[-3px]">
                <FontAwesomeIcon color="rgb(217,179,70)" icon={faPhone} />
              </div>
              <p className="card--content leading-tight ml-2">+40 767519744</p>
            </div>
          </div>

          <div className="flex justify-center">
            <a
              href="https://www.facebook.com/profile.php?id=61552777917978"
              target="_blank"
              className="m-5"
            >
              <i
                className="fa fa-facebook text-[rgb(217,179,70)] text-[40px]"
                aria-hidden="true"
              ></i>
            </a>
            <a
              href="https://instagram.com/sofimarexecutive?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
              className="m-5"
            >
              <i
                className="fa fa-instagram text-[rgb(217,179,70)] text-[40px]"
                aria-hidden="true"
              ></i>
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}
