import image_1 from "./Balcony_comparison.png";
import image_2 from "./11.jpg";
import image_3 from "./15.jpg";
import image_4 from "./14.jpg";
import image_5 from "./16.jpg";
import image_6 from "./1.jpg";
import image_7 from "./8.jpg";
import image_8 from "./9.jpg";
import image_9 from "./7.jpg";
import image_10 from "./3.jpg";
import image_11 from "./12.jpg";
import image_12 from "./13.jpg";

export default [
  { img: image_1, alt: "Comparație balcon, inainte si după" },
  { img: image_2, alt: "Livrare materiale de construcții" },
  { img: image_3, alt: "Montare structură metalică" },
  { img: image_4, alt: "Structură metalică, produs final, nemascat" },
  { img: image_5, alt: "Mascare structură metalică" },
  { img: image_6, alt: "Aplicare rigips" },
  { img: image_7, alt: "Usă inainte de lucrare" },
  { img: image_8, alt: "Lucrare de zugravire" },
  { img: image_9, alt: "Aplicare tavan rigips " },
  { img: image_10, alt: "Mascare structură metalică în tavan" },
  { img: image_11, alt: "Refacere instalație electrică" },
  { img: image_12, alt: "Montare termopane" },
];
