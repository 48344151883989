import React, { useRef } from "react";
import "./App.css";
import LandingPage from "./LandingPage";
import NavBar from "./NavBar";
import AboutUs from "./AboutUs";
import Portofolio from "./Portofolio";
import ContactPage from "./ContactPage";
import useScrollSpy from "react-use-scrollspy";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Galery from "./Galery";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="galery/:id" element={<Galery />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

const HomePage = () => {
  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: 10,
  });
  return (
    <>
      <section id="home" ref={sectionRefs[0]}>
        <LandingPage />
      </section>
      <NavBar />
      <section id="about" ref={sectionRefs[1]}>
        <AboutUs />
      </section>
      <section id="portofolio" ref={sectionRefs[2]}>
        <Portofolio />
      </section>
      <section id="contact" ref={sectionRefs[3]}>
        <ContactPage />
      </section>
    </>
  );
};

export default App;
