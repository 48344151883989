import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Project1Picture from "./assets/Images/Proj1.jpg";
import Project2Picture from "./assets/Images/Proj2.jpg";
import Project3Picture from "./assets/Images/Proj3.jpg";

import Project4Picture from "./assets/Images/Proj6.jpg";
import Project5Picture from "./assets/Images/Proiect_Balotesti/1.jpg";

import Project_13_Septembrie_Images from "./assets/Images/Proiect_13_septembrie/images.ts";
import Project_Lucrari_Exterior_Images from "./assets/Images/Lucrari_amenajare_exterioara/images.ts";
import Project_Lucrari_Interior_Images from "./assets/Images/Lucrari_amenajare_interioara/images.ts";

import Project_Aviatorilor_Images from "./assets/Images/Proiect_aviatiei/images.ts";
import Project_Balotesti_Images from "./assets/Images/Proiect_Balotesti/images.ts";
import { useNavigate } from "react-router-dom";
export default function Portofolio() {
  const navigate = useNavigate();
  const projects = [
    {
      id: 0,
      title: "Amenajare locuința individuală",
      tasks: [
        "Reparații pereți",
        "Aplicare glet",
        "Aplicare vopsea",
        "Tavane gips carton",
        "Profile metalice",
        "Montare gresie",
        "Montare plinte si cornișe",
        "Placare gips carton balcoane",
        "Refacere instalație electrica",
      ],
      showcasePicture: Project1Picture,
      galery: Project_Lucrari_Interior_Images,
    },
    {
      id: 1,
      title: "Proiect 13 Septembrie",
      tasks: [
        "Creare spațiu suplimentar - dormitor",
        "Podire apartament pe 2 etaje pe structură de lemn",
        "Refacere instalație electrică",
        "Perete despărțitor de gips carton pe structură metalică",
      ],
      showcasePicture: Project2Picture,
      galery: Project_13_Septembrie_Images,
    },
    {
      id: 2,
      title: "Lucrări de amenajare exterioară",
      tasks: [
        "Mascare cabluri prin pereți",
        "Aplicare masă de șpaclu",
        "Montare termosistem cu polistiren",
        "Aplicare tencuială decorativă",
        "Placare exterioara cu piatră",
      ],
      showcasePicture: Project3Picture,
      galery: Project_Lucrari_Exterior_Images,
    },
    {
      id: 3,
      title: "Locuință individuală cartier Aviației",
      tasks: [
        "Tencuială mecanizată interior",
        "Tencuială decorativă exterior",
        "Placare mansardă vată minerală",
        "Placare parțială klinker",
        "Montare scafe de gips carton",
        "Placare plafoane gips carton",
        "Aplicare plasă si masă de șpaclu",
        "Aplicare glet grosier",
        "Termosistem exterior cu polistiren",
        "Aplicare glet de finisaj",
      ],
      showcasePicture: Project4Picture,
      galery: Project_Aviatorilor_Images,
    },
    {
      id: 4,
      title: "Locuință individuală Balotesti",
      tasks: [
        "Aplicare tencuială interior",
        "Aplicare tencuială exterior",
        "Termosistem cu vată bazaltică",
        "Aplicare tencuială decorativă",
        "Montare finisaje interioare",
        "Montare hidroizolație soclu",
        "Compactare pamânt de umplutură fundație",
      ],
      showcasePicture: Project5Picture,
      galery: Project_Balotesti_Images,
    },
  ];
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      {projects.map((project, index) => (
        <div key={index} className="grid--container mb-8 h-16 max-w-4xl">
          <div
            className="grid--image"
            style={{
              backgroundImage: `url(${project.showcasePicture})`,
              filter: "grayscale(100%)",
              zIndex: 0,
            }}
          ></div>
          <div className="grid--content p-8 shadow-2xl z-10">
            <h1 className="card--title mb-4 text-4xl font-bold">{project.title}</h1>
            <div className="flex flex-wrap justify-between">
              {project.tasks.map((task, index) => (
                <div key={index} className="flex min-w-[170px]">
                  <div className="">
                    <FontAwesomeIcon color="#228B22" icon={faCheck} />
                  </div>
                  <p className="card--content leading-tight ml-2">{task}</p>
                </div>
              ))}
            </div>
            <button
              onClick={() => {
                navigate("/galery/" + project.id, {
                  state: project.galery,
                });
              }}
              className="bg-transparent py-2 px-4 mt-5"
            >
              GALERIE
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
