import React, { useState } from "react";

export default function () {
  const [open, setOpen] = useState(false);
  return (
    <div className="top-bar sticky top-[-1px] z-50">
      <button
        className="top-bar__nav-toggle hamburger"
        id="top-nav-toggle"
        onClick={() => setOpen(!open)}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <a href="#" className="top-bar__brand">
        Sofimar
      </a>

      <nav
        className={open ? "top-bar__nav" : "top-bar__nav collapsed"}
        id="top-bar__nav"
      >
        <ul className="top-bar__nav-list ">
          <li>
            <a href="#home" onClick={() => setOpen(false)}>
              Pagina principala
            </a>
          </li>
          <li>
            <a href="#about" onClick={() => setOpen(false)}>
              Despre
            </a>
          </li>
          <li>
            <a href="#portofolio" onClick={() => setOpen(false)}>
              Portofoliu
            </a>
          </li>
          <li>
            <a href="#contact" onClick={() => setOpen(false)}>
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
