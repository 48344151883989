import Image1 from "./1.jpg";
import Image2 from "./2.jpg";
import Image3 from "./3.jpg";
import Image4 from "./4.jpg";
import Image5 from "./5.jpg";
import Image6 from "./6.jpg";
import Image7 from "./7.jpg";
import Image8 from "./8.jpg";
import Image9 from "./9.jpg";

export default [
  { img: Image5, alt: "Casa izolata - final 1" },
  { img: Image3, alt: "Interior aplicare gips-carton" },
  { img: Image4, alt: "Casa izolata - final 2" },
  { img: Image6, alt: "Casa izolata - final 3" },
  { img: Image2, alt: "Casa izolata - final usa garaj" },
  { img: Image1, alt: "Montare acoperis" },
  { img: Image7, alt: "Casa izolata - final 4" },
  { img: Image9, alt: "Compactare pamant fundatie" },
  { img: Image8, alt: "Compactare pamant fundatie - scari" },
];
