import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useLocation } from "react-router-dom";
export default function Galery(props) {
  const { state } = useLocation();
  const projImages = state;
  const [images, setImages] = useState(projImages);
  const [chunks, setChunks] = useState([]);
  const [selectedThumb, setSelectedThumb] = useState(0);

  useEffect(() => {
    setImages(projImages);
  }, []);
  useEffect(() => {
    let a = [...images];
    var arrays = [],
      size = 6;
    while (a.length > 0) arrays.push(a.splice(0, size));
    console.log(arrays);
    setChunks(arrays);
  }, [images]);

  return (
    <div className="bg-[#0c0c0c] sm:flex-column md:flex lg:flex xl:flex 2xl:flex sm:h-[100vh] sm:max-h-[100vh] overflow-hidden ">
      <div
        id="myCarousel"
        className="carousel h-[60vh] slide flex m-0 mt-auto mb-auto"
        data-ride="carousel"
        data-interval="false"
      >
        <div className="carousel-inner md:w-[60vw] lg:w-[60vw] xl:w-[60vw] 2xl:w-[60vw]">
          {images.map((image, index) => (
            <div
              key={index}
              className={index === 0 ? "carousel-item active" : "carousel-item"}
              data-slide-number={index}
            >
              <img
                src={image.img}
                className="d-block m-auto h-[60vh] md:w-[50vw] lg:w-[50vw] xl:w-[50vw] 2xl:w-[5-vw]"
                alt={image.alt}
                data-remote="https://source.unsplash.com/Pn6iimgM-wo/"
                data-type="image"
                data-toggle="lightbox"
                data-gallery="example-gallery"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex justify-center mt-auto mb-auto">
        <div
          id="carousel-thumbs"
          className="carousel slide max-w-[400px] flex-column h-[40vh]"
          data-ride="carousel"
          data-interval="false"
        >
          <div className="carousel-inner max-w-[450px] ">
            {chunks.map((chunk, cIndex) => (
              <div
                key={cIndex}
                className={
                  cIndex === 0
                    ? "carousel-item mt-auto mb-auto active"
                    : "carousel-item mt-auto mb-auto"
                }
              >
                <div className="flex flex-wrap mx-5 justify-center mt-5">
                  {chunk.map((image, index) => (
                    <div
                      onClick={() => {
                        setSelectedThumb(index + cIndex * 6);
                      }}
                      key={cIndex * 6 + index}
                      id={"carousel-selector-" + (cIndex * 6 + index)}
                      className={
                        "px-1 mt-auto mb-auto w-[90px] min-w-[90px]" +
                        (index + cIndex * 6 === selectedThumb
                          ? " selected"
                          : "")
                      }
                      data-target="#myCarousel"
                      data-slide-to={cIndex * 6 + index}
                    >
                      <img
                        src={image.img}
                        className="img-fluid"
                        alt={"Thumbnail " + image.alt}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <a
            className="carousel-control-prev"
            href="#carousel-thumbs"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carousel-thumbs"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
}
